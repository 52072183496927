import * as React from "react"
import Footer from "../elements/Footer"
import Newsletter from "../elements/Newsletter"
import Perks from "../elements/Perks"
import { graphql } from "gatsby"
import "flag-icons/css/flag-icons.min.css"
import { SEO } from "../components/SeoNew"
import Navigation from "../components/Navigation"
import { Button } from "../components/Button"
import { PageContainer } from "../components/PageContainer"
import "aos/dist/aos.css";
import { useEffect } from "react"
import img1 from '../images/mockup.png';
import Icon from "@mdi/react"
import { mdiEmail } from "@mdi/js"

const IndexPage = () => {
  let AOS;

  useEffect(() => {
    /**
     * Server-side rendering does not provide the 'document' object
     * therefore this import is required either in useEffect or componentDidMount as they
     * are exclusively executed on a client
     */
    const AOS = require("aos");
    AOS.init({
      once: true,
      duration: 1000,
    });
  }, []);

  useEffect(() => {
    if (AOS) {
      AOS.refresh();
    }
  });
  return (
    <>
      <PageContainer>
        <Navigation />
        <div className="mx-auto max-w-7xl px-6 pb-24 pt-10 sm:pb-32 lg:flex lg:px-8">
          <div className="mx-auto max-w-2xl lg:mx-0 lg:max-w-2xl lg:flex-shrink-0 lg:pt-8">
            <div className="mt-24 sm:mt-32 lg:mt-16 hidden">
              <a href="#" className="inline-flex space-x-6 mb-4">
                    <span
                      className="rounded-full bg-ndna-600/10 px-3 py-1 text-sm font-semibold leading-6 text-ndna-600 ring-1 ring-inset ring-indigo-600/10">
                      What's new
                    </span>
                <span className="inline-flex items-center space-x-2 text-sm font-medium leading-6 text-gray-600">
                      <span>Trusted by multiple clients</span>
                    </span>
              </a>
            </div>
            <h1
              data-aos="fade-up"
              className="font-display text-5xl sm:text-7xl font-medium tracking-tight text-neutral-950 [text-wrap:balance] ">
              Crafting your success with <span className="text-ndna font-bold">software</span>.
            </h1>
            <p className="sm:mt-6 mt-2 sm:text-xl text-sm text-neutral-600" data-aos="fade-up">
              We are an agency specialized in the development of <strong>high quality B2B
              applications</strong>. Our team of experts is dedicated to delivering innovative and tailored solutions
              that empower businesses performance.
            </p>
            <Button href="/contact"
                    className="mt-8 hidden sm:inline-flex bg-ndna hover:text-ndna hover:border-ndna flex items-center"
                    data-aos="fade-up">
              <Icon
                path={mdiEmail}
                className="inline mr-2 h-5 w-5"
              />
              Contact Us
            </Button>
          </div>
          <div
            className="mx-auto mt-16 flex max-w-2xl sm:mt-24 lg:ml-10 lg:mr-0 lg:mt-0 lg:max-w-none lg:flex-none xl:ml-32">
            <div className="max-w-3xl flex-none sm:max-w-5xl lg:max-w-none">
              <div
                className="-m-12 rounded-xl p-2lg:-m-4 lg:rounded-2xl lg:p-4 mt-12">
                <img
                  src={img1}
                  alt="App screenshot"
                  className="w-[42rem] rounded-md"
                />
              </div>
            </div>
          </div>
        </div>
        <Perks />
        <Newsletter />
        {/*<MyTestimonial />*/}
        <Footer />
        {/*<Cookies/>*/}
      </PageContainer>
    </>
  )
}

export default IndexPage

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`

export const Head = () => <>
  <SEO title="Home" />
</>